import React, {useContext, createContext, useEffect } from "react";
import apiRequest from "../services";
import throwNotification from "../GeneralFunctions/throwNotification";

const ProfileContext = createContext()
const useProfile = () => {return useContext(ProfileContext)}

const ProfileContextProvider = ({children}) => {

    const [ profileData, setProfileData ] = React.useState({
        userID:-1, // hedef kullanıcı profilinin id'si
        show:false,
        currentUserIsFallow:false,
        name:'',
        surname:'',
        username:'',
        birthDate:'',
        birthPlace:'',
        password:'',
        image:'',
        livingCity:null,//ikonlu
        createdDate:'',
        biyo:'',
        sex:'',
        edu:'',
        job:'',
        badges:[],  
        liked:{ writers:[],translators:[]  },
        blogs:[],
        followers:[],
        follow:[],
        library:[],
        read:{
            readdedList:[],
            currentlyReadingList:[],
            futureReadingList:[],
            readTarget:0,
            oldReadedList:[]
        },
        store:[],
        currentUserToken:null
    })

    const data = {profileData, setProfileData};

    useEffect(() => {
        if (profileData.show && profileData.userID !== -1) {
            getProfile();
        }
    }, [profileData.userID, profileData.show]);

    const getProfile = async () => {
        const request = await apiRequest({ headers:{Authorization:profileData.currentUserToken}, endpoint:'/profile/'+profileData.userID });
        if ( request.error || !request.responseData || !request.responseData.status ) {
            throwNotification({
                type:'error',
                message:'Bir hata oluştu',
                description: request.errorMessage || request.responseData.message,
                duration:4
            });
            console.error('Alınan hata : ', (request.errorMessage || request.responseData.message) );
            setProfileData({userID:-1, show:false});
        }
        else{
            setProfileData({...profileData, ...request.responseData.response})
        }
    }


    return(
        <ProfileContext.Provider value={ data }>
            {children}
        </ProfileContext.Provider>
    )
}
export{
    useProfile,
    ProfileContextProvider
}