import React from 'react';
import AdminPanel from './layouts/AdminPanel';
import { ConfigProvider } from 'antd';
import tr_TR from 'antd/locale/tr_TR';
import en_US from 'antd/locale/en_US';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Bulten, EksikKitap, IletiAyarlar, Iletiler, Kitaplar, Kullanicilar, MailAyarlari, MailSablon, Rozetler, Youtube, Kategori, YayinEvi, Yazar, Cevirmen } from './Pages/AdminPanel/adminpanelpages';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import ProtectedRoute from './GeneralComponents/ProtectedRoute';
import DKListMainLayout from './layouts/DKListMainLayout';
import AkisSayfasi from './Pages/DKList/AkisSayfasi/AkisSayfasi';
import KitaplarSayfasi from './Pages/DKList/KitaplarSayfasi/KitaplarSayfasi';
import YayinEvleriSayfasi from './Pages/DKList/YayinEvleriSayfasi/YayinEvleriSayfasi';
import CevirmenlerSayfasi from './Pages/DKList/CevirmenlerSayfasi/CevirmenlerSayfasi';
import DKListSecondLayout from './layouts/SecondLayout/DKListSecondLayout';
import YazarlarSayfasi from "./Pages/DKList/YazarlarSayfasi/YazarlarSayfasi";
import KitapSayfasi from './Pages/DKList/KitapSayfasi/KitapSayfasi';
import CevirmenSayfasi from './Pages/DKList/CevirmenSayfasi/CevirmenSayfasi';
import YazarSayfasi from './Pages/DKList/YazarSayfasi/YazarSayfasi';
import VideolarSayfasi from './Pages/DKList/VideolarSayfasi/VideolarSayfasi';
import AskidaKitaplarSayfasi from './Pages/DKList/AskidaKitaplarSayfasi/AskidaKitaplarSayfasi';
import AskidaKitapSayfasi from './Pages/DKList/AskidaKitapSayfasi/AskidaKitapSayfasi';
import MyRouter from './Pages/MyRouter';
import BildirimSayfasi from './Pages/DKList/BildirimSayfasi/BildirimSayfasi';
import ChatSayfasi from './Pages/DKList/ChatSayfasi/ChatSayfasi';
import BlogSayfasi from './Pages/DKList/BlogSayfasi/BlogSayfasi';
import BloglarSayfasi from './Pages/DKList/BloglarSayfasi/BloglarSayfasi';
import Blog from './Pages/AdminPanel/Blog/Blog';
import OkumaHedefSayfasi from './Pages/DKList/OkumaHedefSayfasi/OkumaHedefSayfasi';
import SikayetSayfasi from './Pages/DKList/SikayetSayfasi/SikayetSayfasi';

const App = () => {
    
    const ADMIN_PANEL_BASE = '/admin/';
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    /*React.useEffect(() => {
        if ( location.pathname === '/' ) {
            navigate(t('/akis'));
        }
    }, [location]) */

    return (
        <ConfigProvider locale={i18n.language==='tr' ? tr_TR : en_US}>
            <Helmet>
                <html lang={i18n.language} />
            </Helmet>
            <Routes>
                <Route path={ADMIN_PANEL_BASE} element={<ProtectedRoute homeUrl = {'/'} render={<AdminPanel/>}/>}>
                    <Route path={ADMIN_PANEL_BASE+''}                    element={ <Kitaplar/> }        />
                    <Route path={ADMIN_PANEL_BASE+'kitaplar'}            element={ <Kitaplar/> }        />
                    <Route path={ADMIN_PANEL_BASE+'bildirilen-kitaplar'} element={ <EksikKitap/> }      />
                    <Route path={ADMIN_PANEL_BASE+'kullanicilar'}        element={ <Kullanicilar/> }    />
                    <Route path={ADMIN_PANEL_BASE+'iletiler'}            element={ <Iletiler/> }        />
                    <Route path={ADMIN_PANEL_BASE+'ileti-ayarlari'}      element={ <IletiAyarlar/> }    />
                    <Route path={ADMIN_PANEL_BASE+'e-bulten'}            element={ <Bulten/> }          />
                    <Route path={ADMIN_PANEL_BASE+'mail-ayarlari'}       element={ <MailAyarlari/> }    />
                    <Route path={ADMIN_PANEL_BASE+'mail-sablonu'}        element={ <MailSablon/> }      />
                    <Route path={ADMIN_PANEL_BASE+'youtube'}             element={ <Youtube/> }         />
                    <Route path={ADMIN_PANEL_BASE+'rozetler'}            element={ <Rozetler/> }        />
                    <Route path={ADMIN_PANEL_BASE+'kategoriler'}         element={ <Kategori/> }        />
                    <Route path={ADMIN_PANEL_BASE+'yazar'}               element={ <Yazar/> }           />
                    <Route path={ADMIN_PANEL_BASE+'yayin-evi'}           element={ <YayinEvi/> }        />
                    <Route path={ADMIN_PANEL_BASE+'cevirmen'}            element={ <Cevirmen/> }        />
                    <Route path={ADMIN_PANEL_BASE+'bloglar'}             element={ <Blog/> }            />
                </Route> 
                <Route element={<DKListMainLayout/>}>
                    <Route element={<DKListSecondLayout/>}>
                        <Route path={'/'} element={ <AkisSayfasi/> }/>
                        <Route path={t('/akis')} element={ <AkisSayfasi/> }/>
                        <Route path={t('/akis')+'/:commentID'} element={ <AkisSayfasi/> }/>
                        <Route path={t('/kitaplar')+'/:categorySlug'} element={ <KitaplarSayfasi/> }/>
                        <Route path={t('/kitaplar')} element={ <KitaplarSayfasi/> }/>
                        <Route path={t('/yayinevi')+'/:publisherSlug'} element={ <KitaplarSayfasi/> }/>
                        <Route path={t('/cevirmenler')} element={ <CevirmenlerSayfasi/> }/>
                        <Route path={t('/yayin-evleri')} element={ <YayinEvleriSayfasi/> }/>
                        <Route path={t('/yazarlar')} element={ <YazarlarSayfasi/> }/>
                        <Route path={t('/videolar')} element={ <VideolarSayfasi/> }/>
                        <Route path={t('/askida-kitap')} element={ <AskidaKitaplarSayfasi/> }/>
                        <Route path={t('/bloglar')} element={ <BloglarSayfasi/> }/>
                    </Route>
                    <Route path={t('/yazar') +'/:slug'} element={ <YazarSayfasi/> }/>
                    <Route path={t('/cevirmen') +'/:slug'} element={ <CevirmenSayfasi/> }/>
                    <Route path={t('/kitap') +'/:slug'} element={ <KitapSayfasi/> }/>
                    <Route path={t('/askida-kitap') +'/:slug'} element={ <AskidaKitapSayfasi/> }/>
                    <Route path={t('/bildirimler')} element={ <BildirimSayfasi/> }/>
                    <Route path={t('/mesajlar')} element={ <ChatSayfasi/> }/>
                    <Route path={t('/blog')+'/:slug'} element={ <BlogSayfasi/> }/>
                    <Route path={t('/okuma-hedefleri')+'/:userId'} element={ <OkumaHedefSayfasi/> }/>
                    <Route path={t('/moderator-complaints')} element={<SikayetSayfasi />} />
                </Route>

                <Route path="*" element={<MyRouter/>} />
            </Routes>
        </ConfigProvider>
    );
};
export default App;