import React, { useEffect, useState } from 'react';
import { Table, Spin, message, Modal, Typography, Space } from 'antd';
import apiRequest from '../../../services';
import { useTranslation } from 'react-i18next';
import { useUserAuth } from '../../../Context/UserAuthContext';
import ButtonComponent from '../../../GeneralComponents/ButtonComponent';
import './style.css';

const { Title, Text } = Typography;

const SikayetSayfasi = () => {
    const { t } = useTranslation();
    const { user } = useUserAuth();
    const [loading, setLoading] = useState(false);
    const [complaints, setComplaints] = useState([]);
    const [selectedComplaint, setSelectedComplaint] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const fetchComplaints = async () => {
        setLoading(true);
        try {
            const response = await apiRequest({
                endpoint: '/notice',
                method: 'GET',
                headers: { Authorization: `Bearer ${user.token}` },
            });
            if (response.error || response.responseData.status === false) {
                message.error(t('bir_hata_olustu'));
            } else {
                setComplaints(response.responseData.response.data);
            }
        } catch (err) {
            message.error(t('sunucu_hatasi'));
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchComplaints();
    }, []);

    const handleDeleteComplaint = async (id) => {
        const request = await apiRequest({
            endpoint: `/notice/${id}`,
            method: 'DELETE',
            headers: { Authorization: `Bearer ${user.token}` },
        });

        if (request.error || !request.responseData.status) {
            message.error(t('bir_hata_olustu'));
            return;
        }

        message.success(t('şikayet_silindi'));
        fetchComplaints();
    };

    const handleToggleUserBan = async (userId, isDisabled) => {
        const formData = new FormData();
        formData.append('mode', 'disabled');
        formData.append('newValue', isDisabled ? 0 : 1);

        const request = await apiRequest({
            endpoint: `/user/update-user-admin/${userId}`,
            method: 'POST',
            body: formData,
            headers: { Authorization: `Bearer ${user.token}` },
        });

        if (request.error || !request.responseData.status) {
            message.error(t('bir_hata_olustu'));
            return;
        }

        message.success(isDisabled ? t('yasak_kaldirildi') : t('kullanıcı_yasaklandi'));
        fetchComplaints();
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 70,
        },
        {
            title: t('şikayet_detayı'),
            dataIndex: 'commentDatas',
            key: 'commentDatas',
            render: (commentDatas) => <Text>{commentDatas.comment}</Text>,
            width: 250,
        },
        {
            title: t('şikayet_edilen_kullanıcı'),
            dataIndex: 'user',
            key: 'user',
            render: (user) => <Text>{user.username}</Text>,
            width: 150,
        },
        {
            title: t('kullanıcı_aksiyonları'),
            key: 'actions',
            width: 250,
            render: (item) => (
                <Space>
                    <ButtonComponent
                        onClick={() => handleToggleUserBan(item.user.id, item.user.disable)}
                        type="primary"
                        style={{
                            backgroundColor: item.user.disable ? 'green' : 'red',
                        }}
                    >
                        {item.user.disable ? t('yasagi_ac') : t('yasakla')}
                    </ButtonComponent>
                    <ButtonComponent
                        type="default"
                        onClick={() => {
                            setSelectedComplaint(item);
                            setIsModalVisible(true);
                        }}
                    >
                        {t('goruntule')}
                    </ButtonComponent>
                </Space>
            ),
        },
        {
            title: t('sil'),
            key: 'delete',
            width: 100,
            render: ({ id }) => (
                <ButtonComponent
                    type="primary"
                    danger
                    onClick={() => handleDeleteComplaint(id)}
                >
                    {t('şikayeti_sil')}
                </ButtonComponent>
            ),
        },
    ];

    return (
        <div className="complaintsPage" style={{ padding: '20px', backgroundColor: '#f9f9f9' }}>
            <Title level={3}>{t('moderator_sikayetleri')}</Title>
            {loading ? (
                <Spin size="large" style={{ display: 'block', margin: '20px auto' }} />
            ) : (
                <Table
                    dataSource={complaints.map((complaint) => ({
                        ...complaint,
                        key: complaint.id,
                    }))}
                    columns={columns}
                    pagination={{ pageSize: 10 }}
                    bordered
                    style={{ backgroundColor: '#fff', borderRadius: '8px' }}
                />
            )}

            <Modal
                title={t('şikayet_detayları')}
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={[
                    <ButtonComponent key="close" type="primary" onClick={() => setIsModalVisible(false)}>
                        {t('kapat')}
                    </ButtonComponent>,
                ]}
            >
                {selectedComplaint && (
                    <div style={{ lineHeight: 1.6 }}>
                        <p>
                            <strong>{t('şikayet_edilen')}:</strong> {selectedComplaint.user.username}
                        </p>
                        <p>
                            <strong>{t('şikayet_detayı')}:</strong> {selectedComplaint.commentDatas.comment}
                        </p>
                        <p>
                            <strong>{t('kullanıcı_durumu')}:</strong>{' '}
                            <Text type={selectedComplaint.user.disable ? 'danger' : 'success'}>
                                {selectedComplaint.user.disable ? t('engelli') : t('aktif')}
                            </Text>
                        </p>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default SikayetSayfasi;