import {
    UploadOutlined
} from "@ant-design/icons";
import {
    Button,
    Input,
    Modal,
    Upload
} from "antd";
import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import throwNotification from "../../../GeneralFunctions/throwNotification";
import apiRequest from "../../../services";
import {
    useUserAuth
} from "../../../Context/UserAuthContext";
import {
    t
} from "i18next";

const AddBlogModal = ({
    show,
    setShow,
    query,
    setQuery
}) => {
    const {
        user
    } = useUserAuth();
    const [value, setValue] = React.useState("");
    const [title, setTitle] = React.useState("");
    const [preview, setPreview] = React.useState("");
    const [file, setFile] = React.useState(null);
    const [disabled, setDisabled] = React.useState(false);
    const processedImages = React.useRef(new Set());

    const addBlog = async () => {
        const formData = new FormData();
        formData.append("title", title);
        formData.append("content", value);
        formData.append("preview", preview);
        formData.append("img", file[0].originFileObj);

        const request = await apiRequest({
            endpoint: "/blog",
            body: formData,
            headers: {
                Authorization: user.token
            },
            method: "POST",
        });

        if (request.error || !request.responseData || !request.responseData.status) {
            throwNotification({
                type: "error",
                message: t("bir_hata_olustu"),
                duration: 3,
            });
            console.error("Alınan hata : ", request.errorMessage);
        } else {
            setFile(null);
            setValue("");
            setTitle("");
            setPreview("");
        }
        setQuery({
            ...query
        });
    };

    const uploadImageToServer = async (file) => {
        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await fetch('https://dklist.com/api/upload-image', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('API isteği başarısız oldu.');
            }

            const result = await response.json();
            if (result.status) {
                return result.response; // Görsel URL'si
            } else {
                console.error(result.message);
                return null;
            }
        } catch (error) {
            console.error('Görsel yükleme hatası:', error);
            return null;
        }
    };

    const handleQuillChange = async (content, delta, source, editor) => {
        const pastedImages = Array.from(editor.getContents().ops)
            .filter((op) => op.insert && op.insert.image)
            .map((op) => op.insert.image);

        let updatedContent = content;

        for (const image of pastedImages) {
            if (image.startsWith("data:image") && !processedImages.current.has(image)) {
                processedImages.current.add(image);
                const file = base64ToFile(image, "pasted-image.png");
                const url = await handleImagePaste(file);
                if (url) {
                    // Base64 resmini URL ile değiştir
                    updatedContent = updatedContent.replace(image, url);
                }
            }
        }

        setValue(updatedContent);
    };

    const handleImagePaste = async (file) => {
        const url = await uploadImageToServer(file);
        if (url) {
            return url;
        }
        return null;
    };

    const base64ToFile = (base64, filename) => {
        const arr = base64.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {
            type: mime
        });
    };

    React.useEffect(() => {
        setDisabled(!(file && value && title && preview));
    }, [value, file, title, preview]);

    return ( <
        Modal title = {
            t("blog_ekle")
        }
        centered open = {
            show
        }
        onOk = {
            () => {
                setShow(false);
            }
        }
        onCancel = {
            () => {
                setShow(false);
            }
        }
        footer = {
            null
        } >
        <
        Upload listType = "picture"
        value = {
            file ? [file] : []
        }
        onChange = {
            ({
                fileList: newFile
            }) => {
                setFile([newFile[0]]);
            }
        }
        beforeUpload = {
            () => {
                return false;
            }
        }
        maxCount = {
            1
        } >
        <
        Button style = {
            {
                marginTop: 10,
                marginBottom: 5
            }
        }
        icon = {
            <
            UploadOutlined / >
        } > {
            t("yukle")
        } <
        /Button> < /
        Upload >

        <
        Input maxLength = {
            50
        }
        placeholder = {
            t("blog_basligi")
        }
        value = {
            title
        }
        onChange = {
            (e) => {
                setTitle(e.currentTarget.value);
            }
        }
        style = {
            {
                marginTop: 10
            }
        }
        />

        <
        Input.TextArea maxLength = {
            500
        }
        showCount placeholder = {
            t("blog_onizleme")
        }
        value = {
            preview
        }
        onChange = {
            (e) => {
                setPreview(e.currentTarget.value);
            }
        }
        style = {
            {
                marginTop: 10,
                marginBottom: 10
            }
        }
        />

        <
        div style = {
            {
                height: 300,
                marginTop: 15
            }
        } >
        <
        ReactQuill style = {
            {
                height: 250
            }
        }
        value = {
            value
        }
        onChange = {
            handleQuillChange
        }
        /> < /
        div >

        <
        Button onClick = {
            addBlog
        }
        disabled = {
            disabled
        }
        type = "primary"
        style = {
            {
                marginTop: 15
            }
        } > {
            t("Gonder")
        } <
        /Button> < /
        Modal >
    );
};

export default AddBlogModal;