import React from "react";
import { Helmet } from "react-helmet";
import { Input, Pagination, Select, Spin, Modal, Button, message } from "antd";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import apiRequest from "../../../services";
import throwNotification from "../../../GeneralFunctions/throwNotification";
import { ShareAltOutlined, CopyOutlined } from "@ant-design/icons";
import "./style.css";
import { useParams, useNavigate } from "react-router-dom";

const OkumaHedefSayfasi = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userId } = useParams();
    const [loading, setLoading] = React.useState(false);
    const [query, setQuery] = React.useState({
        page: 1,
        pagePerSize: 10,
        sortBy: "year",
        orderBy: "DESC",
        year: null, // Hedeflenen yıl
    });
    const [data, setData] = React.useState({
        targets: [],
        meta: { page: 1, pagePerSize: 10, filteredCount: 0 },
    });
    const [filteredBooks, setFilteredBooks] = React.useState([]);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [shareModalVisible, setShareModalVisible] = React.useState(false);

    const fetchTargets = React.useCallback(async () => {
        setLoading(true);
        const params = `?search=${searchTerm}&page=${query.page}&pagePerSize=${query.pagePerSize}&sortBy=${query.sortBy}&orderBy=${query.orderBy}`;
        const endpoint = `/profile/${userId}/reading${params}`;
        const request = await apiRequest({ endpoint });

        if (request.error || !request.responseData || !request.responseData.status) {
            throwNotification({
                type: "error",
                message: t("bir_hata_olustu"),
                description: request.responseData?.message || t("sunucu_hatasi"),
                duration: 4,
            });
        } else {
            const response = request.responseData.response;
            // readedList artık tüm yılları içeren dizi olarak geliyor.
            const targets = response.read.readedList || [];
            // Tüm yıllara ait kitapları filtreleyip, istenirse seçilen yıla göre daraltıyoruz.
            const allBooks = targets
                .filter((item) => (query.year ? item.year === query.year : true))
                .flatMap((item) => item.books);
            
            // Arama varsa, kitap ismine göre filtrele
            let finalBooks = allBooks;
            if (searchTerm) {
                finalBooks = allBooks.filter((book) =>
                    book.name.toLowerCase().includes(searchTerm.toLowerCase())
                );
            }
            setData({
                targets,
                meta: {
                    page: query.page,
                    pagePerSize: query.pagePerSize,
                    filteredCount: finalBooks.length,
                },
            });
            setFilteredBooks(finalBooks);
        }
        setLoading(false);
    }, [userId, query, searchTerm, t]);

    React.useEffect(() => {
        fetchTargets();
    }, [fetchTargets]);

    const debouncedSearch = React.useMemo(() => {
        return debounce((value) => {
            setSearchTerm(value);
            setQuery((prevQuery) => ({
                ...prevQuery,
                page: 1,
            }));
        }, 500);
    }, []);

    React.useEffect(() => {
        return () => debouncedSearch.cancel();
    }, [debouncedSearch]);

    // Yılları filtrelemek için, targets içerisinden benzersiz yıl listesini oluşturuyoruz.
    const yearOptions = Array.from(
        new Set(data.targets.map((item) => item.year))
    ).map((year) => ({ value: year, label: year }));

    const shareLink = window.location.href;

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(shareLink);
            message.success(t("link_kopyalandi"));
        } catch (err) {
            message.error(t("kopyalama_basarisiz"));
        }
    };

    return (
        <>
            <Helmet>
                <title>{t("okuma_hedefleri")}</title>
            </Helmet>

            <div
                className="readingTargetsContainer dkBox"
                style={{ margin: "0 auto", padding: "20px" }}
            >
                <div
                    className="readingTargetsHeader"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexWrap: "wrap",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "20px",
                        }}
                    >
                        <h2>{t("okuma_hedefleri")}</h2>
                        <Select
                            defaultValue={query.sortBy}
                            style={{ width: 200 }}
                            onChange={(value) =>
                                setQuery({ ...query, sortBy: value, page: 1 })
                            }
                            options={[
                                { value: "year", label: t("yil") },
                                { value: "targetCount", label: t("hedef_sayisi") },
                                { value: "readCount", label: t("okunan_sayisi") },
                            ]}
                        />
                        <Select
                            placeholder={t("yil_secin")}
                            style={{ width: 200 }}
                            allowClear
                            onChange={(value) =>
                                setQuery({ ...query, year: value, page: 1 })
                            }
                            options={yearOptions}
                        />
                    </div>
                    <Button
                        type="primary"
                        icon={<ShareAltOutlined />}
                        onClick={() => setShareModalVisible(true)}
                    >
                        {t("paylas")}
                    </Button>
                </div>

                <Input
                    placeholder={t("kitap_arayin")}
                    onChange={(e) => debouncedSearch(e.target.value)}
                    style={{ marginBottom: 20, width: "100%" }}
                />

                <div
                    style={{
                        width: "100%",
                        height: 1,
                        backgroundColor: "rgba(0,0,0,.1)",
                        marginBottom: 20,
                    }}
                />

                {loading ? (
                    <div className="loadingContainer">
                        <Spin size="large" />
                    </div>
                ) : (
                    <div className="readingTargetsBody">
                        <div className="selectedYearInfo">
                            {query.year ? (
                                <p>
                                    {t("gosterilen_yil")}: {query.year} - {t("kitap_sayisi")}:{" "}
                                    {filteredBooks.length}
                                </p>
                            ) : (
                                <p>{t("tum_yillar_gosteriliyor")}</p>
                            )}
                        </div>
                        {filteredBooks.length > 0 ? (
                            <div className="booksContainer">
                                {filteredBooks.map((book) => (
                                    <div
                                        key={book.id}
                                        className="bookCard"
                                        onClick={() => navigate(`/kitap/${book.slug}`)}
                                    >
                                        <img src={book.image} alt={book.name} />
                                        <p>{book.name}</p>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className="noResults">{t("sonucBulunamadi")}</p>
                        )}
                        <Pagination
                            total={data.meta.filteredCount}
                            current={query.page}
                            pageSize={query.pagePerSize}
                            onChange={(page, pagePerSize) =>
                                setQuery((prevQuery) => ({
                                    ...prevQuery,
                                    page,
                                    pagePerSize,
                                }))
                            }
                            showSizeChanger
                            style={{ marginTop: 20, textAlign: "right" }}
                        />
                    </div>
                )}
            </div>

            <Modal
                title={t("linki_paylas")}
                visible={shareModalVisible}
                onCancel={() => setShareModalVisible(false)}
                footer={[
                    <Button key="copy" icon={<CopyOutlined />} onClick={handleCopy}>
                        {t("kopyala")}
                    </Button>,
                    <Button key="close" onClick={() => setShareModalVisible(false)}>
                        {t("kapat")}
                    </Button>,
                ]}
            >
                <Input value={shareLink} readOnly />
            </Modal>
        </>
    );
};

export default OkumaHedefSayfasi;